import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import api from "../../api/apiConfig";
const taskApi = factory.get("task");

const initialState = {
  tasks: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      status: [],
      task_id: "",
      responsible_id: null,
      city_id: null,
      customers_source: null,
      has_responsible: null,
      has_team_leader: null,
      team_leader_id: null,
      created_by_id: null,
      ftth_ticket_number: "",
      is_delayed: null,
    },
    filters_dialog: false,
  },
  task: {
    form: {
      id: null,
      type: 1,
      status: "new",
      note: "",
      name: "",
      mobile: "",
      is_external: false,
      customer_id: null,
      cabinet_id: null,
      pole_id: null,
      city_id: null,
      attachment: [],
      is_change_subscription: false,
      source_type_id: null,
      record: null,
      ftth_ticket_number: "",
      period: 1,
    },
    dialog: false,
    loading: false,
    errors: {},
  },
  task_maintenance: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      type_id: null,
      status: [],
      customer_id: null,
      city_id: null,
      category: "",
      responsible_id: null,
      cabinet_id: null,
      task_id: "",
      client_id: null,
      customers_source: null,
      is_external: null,
      has_responsible: null,
      has_team_leader: null,
      team_leader_id: null,
      created_by_id: null,
      is_qc_maintenance: null,
      ftth_ticket_number: "",
      is_delayed: null,
    },
    filters_dialog: false,
  },
  task_subscription_request: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      user_id: null,
      city_id: null,
      cabinet_ids: [],
      responsible_id: null,
      status: [{ value: "جديد", key: "new" }],
      customers_source: "1",
      created_by_id: null,
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      ftth_ticket_number: "",
      is_delayed: null,
    },
    filters_dialog: false,
  },
  fat_clearance_tasks: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      cabinet_id: null,
      pole_id: null,
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      type_id: null,
      status: [
        { value: "جديد", key: "new" },
        {
          key: "in_progress",
          value: "جاري المعالجة",
        },
      ],
      is_full_pole: null,
      customer_id: null,
      city_id: null,
      category: "",
      responsible_id: null,
      task_id: "",
      client_id: null,
      customers_source: null,
      is_external: null,
      has_responsible: null,
      has_team_leader: null,
      team_leader_id: null,
      created_by_id: null,
      is_qc_maintenance: null,
      ftth_ticket_number: "",
      is_delayed: null,
    },
    filters_dialog: false,
  },
  fat_clearance_task: {
    form: {
      id: null,
      type_id: 6,
      cabinet_id: null,
      pole_id: null,
      note: "",
    },
    dialog: false,
    loading: false,
    errors: {},
  },
  status: {
    data: [],
    loading: false,
  },
  solutions: {
    data: [],
    loading: false,
  },
  types: {
    data: [],
    loading: false,
  },
  task_details: {
    data: {
      id: null,
      type: "",
      name: "",
      mobile: "",
      note: "",
      status: "",
      customer: null,
      responsible_id: null,
      responsible_name: null,
      created_by_id: "",
      solution: [],
      created_by: "",
      attachment: "",
      replies: [],
      maintenance_form: null,
    },
    loading: false,
  },
  reply: {
    progress: 0,
    form: {
      type: "reply",
      task_id: "",
      note: "",
      attachment: [],
      video: null,
      record: null,
      send_comment_to_ftth: true,
    },
    loading: false,
    dialog: false,
    errors: {},
  },
  responsible: {
    form: {
      tasks: [],
      responsible_id: null,
      from: "task",
    },
    loading: false,
    dialog: false,
  },
  assign_team_leader: {
    form: {
      id: "",
      team_leader_id: null,
      from: "task",
    },
    loading: false,
    dialog: false,
  },
  task_notifications: {
    new_customer: 0,
    maintenance: 0,
    subscription_request: 0,
  },
  maintenance_details: {
    dialog: false,
    loading: false,
    bill: {},
    form: {
      amount: "",
      attachment: null,
      connectors_count: "",
      router_type: null,
      is_router_change: null,
      router_serial: null,
      cable_length: "",
      cable_type: null,
      has_connectors: false,
      has_router: false,
      has_cabel: false,
      has_charger: false,
      has_ups: false,
      has_lan: false,
      has_re_subscribe: false,
      is_free: false,
      free_reason: "",
      note: "",
      solutions: [],
      wifi_router_id: null,
      wifi_router_serial: "",
    },
  },
  collection_fees_dialog: {
    loading: false,
    dialog: false,
    collection_fees_amount: 0,
    data: {},
  },
  change_task_type: {
    loading: false,
    dialog: false,
    form: {
      task_id: null,
      type_id: null,
    },
  },
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    // Tasks
    setTasks: (state, action) => {
      state.tasks.data = action.payload.data.data;
      state.tasks.total = action.payload.data.total;
    },
    setTasksFiltersDialog: (state, action) => {
      state.tasks.filters_dialog = !state.tasks.filters_dialog;
    },
    setTaskReplyDialog: (state, { payload }) => {
      state.reply.dialog = !state.reply.dialog;
      state.reply.form.task_id = payload?.id || "";
      state.reply.form.type = payload?.type || "reply";
      state.reply.progress = 0;
    },
    resetForm: (state, action) => {
      state.task.form = initialState.task.form;
    },
    setFilters: (state, { payload }) => {
      if (payload.status) {
        state.tasks.filters.status = payload.status;
      } else {
        state.tasks.filters = {
          ...state.tasks.filters,
          ...payload,
        };
      }
    },
    setTaskDetails: (state, { payload }) => {
      state.task_details.data = { ...payload };
    },
    resetTaskDetails: (state, { payload }) => {
      state.task_details.data = initialState.task_details;
    },
    resetFilters: (state) => {
      state.tasks.filters = initialState.tasks.filters;
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.task.form.type = payload.type;

        if (payload.data?.customer_id) {
          state.task.form.customer_id = {
            id: payload.data.customer_id,
            full_name: payload.data.name,
            mobile: payload.data.mobile,
          };
        }

        //if the form type is subscription request and change subscription is true
        if (payload?.is_change_subscription) {
          state.task.form.id = payload.data.id;
          state.task.form.account_type_id = {
            id: payload.data.account_type_id,
            name: payload.data.account_type_name,
          };
          state.task.form.last_amount = payload.data.amount;
          state.task.form.period = payload.data.period || 1;
          state.task.form.is_change_subscription =
            payload?.is_change_subscription || false;
        }
      } else {
        state.task.form = initialState.task.form;
      }
      state.task.dialog = !state.task.dialog;
    },
    // END Tasks

    //Maintenance Tasks
    setMaintenanceTasks: (state, action) => {
      state.task_maintenance.data = action.payload.data.data;
      state.task_maintenance.total = action.payload.data.total;
    },
    setMaintenanceTasksFiltersDialog: (state, action) => {
      state.task_maintenance.filters_dialog =
        !state.task_maintenance.filters_dialog;
    },
    setMaintenanceFilters: (state, { payload }) => {
      if (payload.status) {
        state.task_maintenance.filters.status = payload.status;
      } else {
        state.task_maintenance.filters = {
          ...state.task_maintenance.filters,
          ...payload,
        };
      }
    },
    resetMaintenanceFilters: (state) => {
      state.task_maintenance.filters = initialState.task_maintenance.filters;
    },
    setMaintenanceDetailsDialog: (state) => {
      const maintenance_form = state.task_details.data?.maintenance_form;
      //check if maintenance_form is existed then it's edit and fill maintenance_detials_form with it
      if (maintenance_form?.id) {
        state.maintenance_details.form = {
          ...state.maintenance_details.form,
          ...maintenance_form,

          has_connectors:
            maintenance_form?.connectors_count >= 1 ? true : false,
          has_router: maintenance_form?.router_type ? true : false,
          has_cabel: maintenance_form?.cable_length >= 1 ? true : false,
          is_router_change: maintenance_form?.is_router_change ? true : false,
          solutions: maintenance_form?.solution || [],
          wifi_router_id: maintenance_form?.wifi_router_id
            ? {
                id: maintenance_form?.wifi_router_id,
                name: maintenance_form?.wifi_router_name,
                price: 40000,
              }
            : null,
          wifi_router_serial: maintenance_form?.wifi_router_serial || "",
        };
      } else {
        state.maintenance_details.form = initialState.maintenance_details.form;
      }

      state.maintenance_details.bill = {};
      state.maintenance_details.dialog = !state.maintenance_details.dialog;
    },
    setMaintenanceDetailsPrice: (state, { payload }) => {
      state.maintenance_details.bill = payload?.bill || {};
    },
    // END Maintenance Tasks

    // Subscription Request Tasks
    setSubscriptionRequestTasks: (state, action) => {
      state.task_subscription_request.data = action.payload.data.data;
      state.task_subscription_request.total = action.payload.data.total;
    },
    setSubscriptionRequestTaskFilterDialog: (state) => {
      state.task_subscription_request.filters_dialog =
        !state.task_subscription_request.filters_dialog;
    },
    setSubscriptionRequestTaskFilters: (state, { payload }) => {
      if (payload.status) {
        state.task_subscription_request.filters.status = payload.status;
      } else {
        state.task_subscription_request.filters = {
          ...state.task_subscription_request.filters,
          ...payload,
        };
      }
    },
    resetSubscriptionRequestTaskFilters: (state) => {
      state.task_subscription_request.filters =
        initialState.task_subscription_request.filters;
    },
    // END Subscription Request Tasks

    // Fat clearance Tasks
    setFatClearanceTasks: (state, action) => {
      state.fat_clearance_tasks.data = action.payload.data.data;
      state.fat_clearance_tasks.total = action.payload.data.total;
    },
    setFatClearanceTaskFilterDialog: (state) => {
      state.fat_clearance_tasks.filters_dialog =
        !state.fat_clearance_tasks.filters_dialog;
    },
    setFatClearanceTaskFilters: (state, { payload }) => {
      if (payload.status) {
        state.fat_clearance_tasks.filters.status = payload.status;
      } else {
        state.fat_clearance_tasks.filters = {
          ...state.fat_clearance_tasks.filters,
          ...payload,
        };
      }
    },
    resetFatClearanceTaskFilters: (state) => {
      state.fat_clearance_tasks.filters =
        initialState.fat_clearance_tasks.filters;
    },
    setFatClearanceDialog: (state, { payload }) => {
      state.fat_clearance_task.dialog = !state.fat_clearance_task.dialog;
    },
    // END  Fat clearance Tasks

    setStatus: (state, { payload }) => {
      state.status.data = payload.data.data;
      state.status.loading = false;
    },
    setRsponsibleDialog: (state, { payload }) => {
      if (payload) {
        state.responsible.form.tasks = payload.tasks;
        state.responsible.form.from = payload.from;
      }
      state.responsible.dialog = !state.responsible.dialog;
    },
    setSolutions: (state, { payload }) => {
      state.solutions.data = payload.data.data;
      state.solutions.loading = false;
    },
    setTypes: (state, { payload }) => {
      state.types.data = payload.data.data;
      state.types.loading = false;
    },
    setReplyProgress: (state, { payload }) => {
      state.reply.progress = payload;
    },
    setTaskNotifications: (state, { payload }) => {
      state.task_notifications.new_customer =
        payload.data?.data[0].new_customer;
      state.task_notifications.subscription_request =
        payload.data?.data[0].subscription_request;
      state.task_notifications.maintenance = payload.data?.data[0].maintenance;
    },
    setTeamLeaderDialog: (state, { payload }) => {
      if (payload) {
        state.assign_team_leader.form.id = payload.id;
        state.assign_team_leader.form.from = payload.from;
        state.assign_team_leader.form.team_leader_id = payload.team_leader_id
          ?.id
          ? payload.team_leader_id
          : null;
      } else {
        state.assign_team_leader.form = initialState.assign_team_leader.form;
      }
      state.assign_team_leader.dialog = !state.assign_team_leader.dialog;
    },
    setCollectionFeesDialog: (state, { payload }) => {
      if (payload?.id) {
        state.collection_fees_dialog.data = payload;
      }
      state.collection_fees_dialog.dialog =
        !state.collection_fees_dialog.dialog;
    },
    setCollectionFeesAmount: (state, { payload }) => {
      state.collection_fees_dialog.collection_fees_amount = payload || 0;
    },
    setChangeTaskTypeDialog: (state, { payload }) => {
      state.change_task_type.form.task_id = payload || "";
      state.change_task_type.form.type_id = null;
      state.change_task_type.dialog = !state.change_task_type.dialog;
    },
  },
});

export const {
  setLoading,
  setTasks,
  setFilters,
  setStatus,
  resetFilters,
  setDialog,
  resetForm,
  setTasksFiltersDialog,
  setMaintenanceTasksFiltersDialog,
  setTaskReplyDialog,
  setTaskDetails,
  setRsponsibleDialog,
  setSolutions,
  setTypes,
  setMaintenanceTasks,
  setMaintenanceFilters,
  resetMaintenanceFilters,
  setReplyProgress,
  setTaskNotifications,

  setSubscriptionRequestTasks,
  setSubscriptionRequestTaskFilterDialog,
  setSubscriptionRequestTaskFilters,
  resetSubscriptionRequestTaskFilters,
  setTeamLeaderDialog,
  resetTaskDetails,
  setMaintenanceDetailsDialog,
  setMaintenanceDetailsPrice,
  setCollectionFeesDialog,
  setCollectionFeesAmount,

  setFatClearanceTasks,
  setFatClearanceTaskFilterDialog,
  setFatClearanceTaskFilters,
  resetFatClearanceTaskFilters,
  setFatClearanceDialog,
  setChangeTaskTypeDialog,
} = taskSlice.actions;

export default taskSlice.reducer;

const toNumber = (value) => {
  return typeof value === "number" ? value : parseInt(value.replace(/,/g, ""));
};
const index =
  (params = null) =>
  async (dispatch, getState) => {
    try {
      const filters = getState().task.tasks.filters;
      const filters_ = {
        ...filters,
        city_id: filters.city_id?.id || null,
        team_leader_id: filters.team_leader_id?.id || null,
        responsible_id: filters.responsible_id?.id || null,
        status: filters.status ? filters.status.map((item) => item.key) : null,
        created_by_id: filters.created_by_id?.id || null,
        ...params,
      };
      dispatch(setLoading("tasks"));
      const res = await taskApi.newCustomers(filters_);
      dispatch(setTasks(res));
      dispatch(setLoading("tasks"));
    } catch (err) {
      dispatch(setLoading("tasks"));
      dispatch(
        showNotification({
          type: "error",
          message: err.response.data.errors[0],
        })
      );
      throw new Error(err);
    }
  };
const maintenanceTasks =
  (params = null) =>
  async (dispatch, getState) => {
    try {
      const filters = getState().task.task_maintenance.filters;
      const filters_ = {
        ...filters,
        customer_id: filters.customer_id?.id || null,
        city_id: filters.city_id?.id || null,
        responsible_id: filters.responsible_id?.id || null,
        cabinet_id: filters.cabinet_id?.id || null,
        client_id: filters.client_id?.id || null,
        team_leader_id: filters.team_leader_id?.id || null,
        status: filters.status ? filters.status.map((item) => item.key) : null,
        is_external:
          filters.is_external !== null ? (filters.is_external ? 1 : 0) : null,
        created_by_id: filters.created_by_id?.id || null,
        ...params,
      };
      dispatch(setLoading("task_maintenance"));
      const res = await taskApi.maintenanceTasks(filters_);
      dispatch(setMaintenanceTasks(res));
      dispatch(setLoading("task_maintenance"));
    } catch (err) {
      dispatch(setLoading("task_maintenance"));
      dispatch(
        showNotification({
          type: "error",
          message: err.response.data.errors[0],
        })
      );
      throw new Error(err);
    }
  };
const subscriptionRequestTasks =
  (params = null) =>
  async (dispatch, getState) => {
    try {
      const filters = getState().task.task_subscription_request.filters;

      const filters_ = {
        ...filters,
        user_id: filters.user_id?.id || null,
        city_id: filters.city_id?.id || null,
        responsible_id: filters.responsible_id?.id || null,
        cabinet_ids:
          filters.cabinet_ids.length > 0
            ? filters.cabinet_ids.map((item) => item.id)
            : [],
        status: filters.status
          ? filters.status.map((item) => item.key)
          : ["new"],
        created_by_id: filters.created_by_id?.id || null,
        ...params,
      };
      dispatch(setLoading("task_subscription_request"));
      const res = await taskApi.subscriptionRequestTasks(filters_);
      dispatch(setSubscriptionRequestTasks(res));
      dispatch(setLoading("task_subscription_request"));
    } catch (err) {
      dispatch(setLoading("task_subscription_request"));
      dispatch(
        showNotification({
          type: "error",
          message: err.response.data.errors[0],
        })
      );
      throw new Error(err);
    }
  };
const fatClearanceTasks =
  (params = null) =>
  async (dispatch, getState) => {
    try {
      const filters = getState().task.fat_clearance_tasks.filters;
      const filters_ = {
        ...filters,
        city_id: filters.city_id?.id || null,
        responsible_id: filters.responsible_id?.id || null,
        cabinet_id: filters.cabinet_id || filters.cabinet_id?.id,
        pole_id: filters.pole_id || filters.pole_id?.id,
        // status: filters.status
        //   ? filters.status.map((item) => item.key)
        //   : ["new"],
        created_by_id: filters.created_by_id?.id || null,
        ...params,
      };
      dispatch(setLoading("fat_clearance_tasks"));
      const res = await taskApi.fatClearanceTask(filters_);
      dispatch(setFatClearanceTasks(res));
      dispatch(setLoading("fat_clearance_tasks"));
    } catch (err) {
      dispatch(setLoading("fat_clearance_tasks"));
      dispatch(
        showNotification({
          type: "error",
          message: err.response.data.errors[0],
        })
      );
      throw new Error(err);
    }
  };

const status = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("status"));
    const res = await taskApi.status();
    dispatch(setStatus(res));
  } catch (err) {
    dispatch(setLoading("status"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const solutions = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("solutions"));
    const res = await taskApi.solutions();
    dispatch(setSolutions(res));
  } catch (err) {
    dispatch(setLoading("solutions"));
    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ ما",
      })
    );
    throw new Error(err);
  }
};
const types = () => async (dispatch) => {
  try {
    dispatch(setLoading("types"));
    const res = await taskApi.types();
    dispatch(setTypes(res));
  } catch (err) {
    dispatch(setLoading("types"));
    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ ما",
      })
    );
    throw new Error(err);
  }
};
const sendSolutions = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("solutions"));
    const formData = new FormData();

    data.solutions.forEach((solutionId) => {
      formData.append("solutions[]", solutionId);
    });
    formData.append(
      "amount",
      data.type === "customer maintenance"
        ? data.amount !== null && data.amount !== undefined
          ? toNumber(data.amount)
          : null
        : null
    );
    formData.append("bill", data.bill?.length > 0 ? data?.bill[0].file : "");
    formData.append("_method", "PUT");
    formData.append(
      "is_free",
      data.type === "customer maintenance" ? data.is_free : true
    );

    await taskApi.addSolutions(data.task_id, formData);
    dispatch(
      showNotification({
        type: "success",
        message: "تم إضافة الحلول بنجاح",
      })
    );
    dispatch(setLoading("solutions"));
    dispatch(getTaskByid(data.task_id));
    return true;
  } catch (err) {
    dispatch(setLoading("solutions"));
    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ ما",
      })
    );
    throw new Error(err);
  }
};
const changeStatus = (data, page) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("tasks"));
    dispatch(setLoading("task_details"));
    const formData = new FormData();

    formData.append("id", data.id);
    formData.append("status", data.status);
    // formData.append("amount", data.amount ? toNumber(data.amount) : "");
    // formData.append("bill", data.bill?.length > 0 ? data?.bill[0].file : "");
    formData.append("_method", "PUT");

    await taskApi.changeStatus(data.id, formData);
    dispatch(setLoading("tasks"));
    dispatch(setLoading("task_details"));

    dispatch(
      showNotification({
        type: "success",
        message: "تم تغيير حالة المهمة بنجاح",
      })
    );
    if (data.from === "task_details") {
      dispatch(getTaskByid(data.id));
    } else {
      switch (page) {
        case "task":
          dispatch(index());
          break;
        case "maintenance":
          dispatch(maintenanceTasks());
          break;
        case "subscriptionRequest":
          dispatch(subscriptionRequestTasks());
          break;
        case "fatClearanceTasks":
          dispatch(fatClearanceTasks());
          break;
        default:
          dispatch(index());
          break;
      }
    }
  } catch (err) {
    dispatch(setLoading("tasks"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const getTaskByid = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("task_details"));
    const res = await taskApi.showById(id);
    const res_data = res.data.data[0];
    dispatch(setTaskDetails(res_data));

    dispatch(setLoading("task_details"));
  } catch (err) {
    dispatch(setLoading("task_details"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const create = (data, formType) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("task"));
    const formData = new FormData();

    formData.append("record", data.record ? JSON.stringify(data.record) : null);
    formData.append("ftth_ticket_id", data.ftth_ticket_id || "");
    formData.append("ftth_panel_id", data.ftth_panel_id || "");

    //if the form type is subscription request
    if (
      formType === "subscriptionRequest" ||
      formType === "subscriptionRequestCustomerById"
    ) {
      formData.append("type_id", 5); //"subscription_request"
      formData.append(
        "customer_id",
        data.customer_id ? data.customer_id.id : ""
      );
      formData.append("amount", toNumber(data.amount));
      formData.append("account_type_id", data.account_type_id?.id);
      formData.append("period", data.period || 1);
      formData.append("note", data.note);
      formData.append("ftth_ticket_number", data.ftth_ticket_number || "");
      data.offer_id && formData.append("offer_id", data.offer_id);
    }
    //if the form type is maintenance and new customers
    else {
      if (data.type === 1) {
        formData.append("source_type_id", data.source_type_id?.id);
        formData.append("city_id", data.city_id?.id);
        formData.append("mobile", data.mobile);
        formData.append("ftth_ticket_number", data.ftth_ticket_number || "");
        formData.append("name", data.name);
        formData.append(
          "cabinet_id",
          data.cabinet_id ? data.cabinet_id.id : ""
        );
        formData.append("pole_id", data.pole_id ? data.pole_id.id : "");
        if (data.client_id?.id)
          formData.append("client_id", data.client_id?.id);
      } else {
        formData.append(
          "customer_id",
          data.customer_id ? data.customer_id.id : ""
        );
        formData.append(
          "cabinet_id",
          data.cabinet_id ? data.cabinet_id.id : ""
        );
        formData.append("pole_id", data.pole_id ? data.pole_id.id : "");
        formData.append("is_external", data.is_external ? "1" : "0");
        formData.append("category", data.category);
        formData.append("ftth_ticket_number", data.ftth_ticket_number || "");
      }
      formData.append("note", data.note);
      formData.append("status", data.status);
      formData.append("type_id", data.type);

      if (data.attachment.length > 0) {
        data.attachment.forEach((item, index) => {
          formData.append(`attachment[${index}]`, item);
        });
      }
    }

    await taskApi.create(formData);
    dispatch(setLoading("task"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم إنشاء الطلب بنجاح",
      })
    );
    dispatch(
      setDialog({
        type: "",
      })
    );
    dispatch(resetForm());

    switch (formType) {
      case "subscriptionRequest":
        dispatch(subscriptionRequestTasks());
        break;
      case "subscriptionRequestCustomerById":
        dispatch(
          subscriptionRequestTasks({
            user_id: data.customer_id?.id,
            status: [],
            customers_source: null,
          })
        );
        break;
      case "maintenanceCustomerById":
        dispatch(
          maintenanceTasks({
            customer_id: data.customer_id?.id,
          })
        );
        break;
      case "maintenance":
        dispatch(maintenanceTasks());
        break;
      case "customer":
        dispatch(index());
        break;
      default:
        dispatch(index());
        break;
    }
  } catch (err) {
    dispatch(setLoading("task"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const createFatClearance = (data, formType) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("fat_clearance_tasks"));
    const formData = new FormData();
    formData.append("type_id", data.type_id);
    formData.append("cabinet_id", data.cabinet_id ? data.cabinet_id.id : "");
    formData.append("pole_id", data.pole_id ? data.pole_id.id : "");
    formData.append("related_task_id", data.related_task_id || "");
    formData.append("note", data.note || "");
    await taskApi.createFatClearanceTask(formData);
    dispatch(setLoading("fat_clearance_tasks"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم إنشاء المهمة بنجاح",
      })
    );
    dispatch(setFatClearanceDialog());

    if (!data.related_task_id) {
      dispatch(Tasks.fatClearanceTasks());
    } else {
      dispatch(Tasks.getTaskByid(data.related_task_id));
    }
  } catch (err) {
    dispatch(setLoading("fat_clearance_tasks"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const deleteTask = (id) => async (dispatch, getState) => {
  try {
    await taskApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "تم الحذف بنجاح",
      })
    );
    return {
      success: true,
    };
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ ما",
      })
    );
    throw new Error(err);
  }
};
const deleteReply = (reply_id) => async (dispatch, getState) => {
  try {
    const { id } = getState().task.task_details.data;
    dispatch(setLoading("reply"));
    await taskApi.deleteReply(reply_id);
    dispatch(
      showNotification({
        type: "success",
        message: "تم حذف الرد بنجاح",
      })
    );
    dispatch(getTaskByid(id));
    dispatch(setLoading("reply"));
  } catch (err) {
    dispatch(setLoading("reply"));

    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ ما",
      })
    );
    throw new Error(err);
  }
};
const reply = (data, is_have_permission) => async (dispatch, getState) => {
  try {
    const { id } = getState().task.task_details.data;
    dispatch(setLoading("reply"));
    const formData = new FormData();
    formData.append("task_id", data.task_id);
    formData.append("note", data.note);
    formData.append("video", data.video);
    if (data.isMaintenanceTask) {
      formData.append(
        "send_comment_to_ftth",
        is_have_permission ? (data.send_comment_to_ftth ? "1" : "0") : "1"
      );
    }

    if (data.attachment.length > 0) {
      data.attachment.forEach((item, index) => {
        formData.append(`attachment[${index}]`, item);
      });
    }
    await api.post(`/api/v1/task-reply`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        dispatch(setReplyProgress(percent));
      },
    });
    // await taskApi.reply(formdata);
    dispatch(setLoading("reply"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم إضافة الرد بنجاح",
      })
    );
    dispatch(setTaskReplyDialog());
    dispatch(getTaskByid(id));
  } catch (err) {
    dispatch(setReplyProgress(0));
    dispatch(setLoading("reply"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const assignResponsible = (data, page) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("responsible"));
    await taskApi.assignMultipleResponsible({
      tasks: data.tasks,
      responsible_id: data.responsible_id.id,
    });
    dispatch(setLoading("responsible"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم تعيين المسؤول بنجاح",
      })
    );
    dispatch(setRsponsibleDialog());
    if (data.from === "task_details") {
      dispatch(getTaskByid(data.tasks[0]));
    } else {
      switch (page) {
        case "task":
          dispatch(index());
          break;
        case "maintenance":
          dispatch(maintenanceTasks());
          break;
        case "subscriptionRequest":
          dispatch(subscriptionRequestTasks());
          break;
        case "fatClearanceTasks":
          dispatch(fatClearanceTasks());
          break;
        default:
          dispatch(index());
          break;
      }
    }
  } catch (err) {
    dispatch(setLoading("responsible"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const changeIsExternalStatus = (data) => async (dispatch, getState) => {
  try {
    await taskApi.changeIsExternalStatus(data);
    dispatch(
      showNotification({
        type: "success",
        message: "تم تغيير الحالة بنجاح",
      })
    );
    dispatch(getTaskByid(data.id));
    return {
      success: true,
    };
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const getTasksNotifications = () => async (dispatch) => {
  try {
    const res = await taskApi.getTaskNotification();
    dispatch(setTaskNotifications(res));
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const changeSubscriptionAccountRequestsTask = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("task"));
    await taskApi.changeSubscriptionRequests(data.id, {
      account_type_id: data.account_type_id.id,
      amount: toNumber(data.amount),
      offer_id: data.offer_id,
      period: data.period || 1,
    });
    dispatch(setLoading("task"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم تغيير نوع الاشتراك بنجاح",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(subscriptionRequestTasks());
  } catch (err) {
    dispatch(setLoading("task"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const toExcel = () => async (dispatch, getState) => {
  try {
    const filters = getState().task.tasks.filters;
    dispatch(setLoading("tasks"));
    const res = await taskApi.exportToExcel({
      ...filters,
      city_id: filters.city_id?.id || null,
      responsible_id: filters.responsible_id?.id || null,
      team_leader_id: filters.team_leader_id?.id || null,
      status: filters.status ? filters.status.map((item) => item.key) : null,
      is_excel: 1,
      created_by_id: filters.created_by_id?.id || null,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = "tasks.xlsx";
    a.click();
    dispatch(setLoading("tasks"));
  } catch (err) {
    dispatch(setLoading("tasks"));
    throw new Error(err);
  }
};
const toExcelTaskMaintenance = () => async (dispatch, getState) => {
  try {
    const filters = getState().task.task_maintenance.filters;
    dispatch(setLoading("task_maintenance"));

    const custom_filters = {
      ...filters,
      user_id: filters.user_id?.id || null,
      city_id: filters.city_id?.id || null,
      responsible_id: filters.responsible_id?.id || null,
      cabinet_id: filters.cabinet_id?.id || null,
      client_id: filters.client_id?.id || null,
      team_leader_id: filters.team_leader_id?.id || null,
      created_by_id: filters.created_by_id?.id || null,
      status: filters.status ? filters.status.map((item) => item.key) : null,
      is_external:
        filters.is_external !== null ? (filters.is_external ? 1 : 0) : null,
      is_excel: 1,
    };
    const res = await taskApi.exportToExcelMaintenance(custom_filters);
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = "task_maintenance.xlsx";
    a.click();
    dispatch(setLoading("task_maintenance"));
  } catch (err) {
    dispatch(setLoading("task_maintenance"));
    throw new Error(err);
  }
};
const assignTeamLeader = (data, page) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("assign_team_leader"));
    await taskApi.assignTeamLeader({
      id: data.id,
      team_leader_id: data.team_leader_id?.id,
    });
    dispatch(setLoading("assign_team_leader"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم تعيين المسؤول بنجاح",
      })
    );
    dispatch(setTeamLeaderDialog());
    if (data.from === "task_details") {
      dispatch(getTaskByid(data.tasks[0]));
    } else {
      switch (page) {
        case "task":
          dispatch(index());
          break;
        case "maintenance":
          dispatch(maintenanceTasks());
          break;
        default:
          dispatch(index());
          break;
      }
    }
  } catch (err) {
    dispatch(setLoading("assign_team_leader"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const addNewRecord = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("reply"));
    await taskApi.addNewRecord({
      task_id: data.task_id,
      record: data.record,
    });
    dispatch(setLoading("reply"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم اضافة التسجيل الصوتي بنجاح",
      })
    );
    dispatch(getTaskByid(data.task_id));
    dispatch(setTaskReplyDialog());
  } catch (err) {
    dispatch(setLoading("reply"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const getRecordFullPath = (path) => async (dispatch) => {
  try {
    dispatch(setLoading("task"));
    const res = await taskApi.getRecordFullPath(path);
    dispatch(setLoading("task"));

    const audioBlob = new Blob([res.data], { type: "audio/wav" });
    const audioUrl = URL.createObjectURL(audioBlob);

    return audioUrl;
  } catch (err) {
    dispatch(setLoading("task"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response?.data?.errors[0] || "An error occurred",
      })
    );
    throw new Error(err);
  }
};
const checkMaintenanceDetailsPrice = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("maintenance_details"));
    const res_price = await taskApi.maintenanceDetailsPrice(data);
    dispatch(setMaintenanceDetailsPrice(res_price.data.data));
    dispatch(setLoading("maintenance_details"));
  } catch (err) {
    dispatch(setLoading("maintenance_details"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const maintenanceDetailsForm = (data) => async (dispatch, getState) => {
  try {
    const bill = getState().task.maintenance_details.bill;
    dispatch(setLoading("maintenance_details"));
    const formData = new FormData();
    formData.append("connectors_count", data.connectors_count);
    formData.append("amount", toNumber(bill.amount));
    formData.append("attachment", data.attachment);
    formData.append("router_type", data.router_type);
    formData.append("is_router_change", data.is_router_change);
    formData.append("router_serial", data.router_serial);
    formData.append("cable_length", data.cable_length);
    formData.append("has_charger", data.has_charger);
    formData.append("has_ups", data.has_ups);
    formData.append("has_lan", data.has_lan);
    formData.append("has_re_subscribe", data.has_re_subscribe);
    formData.append("is_free", data.is_free);
    formData.append("free_reason", data.free_reason);
    formData.append("note", data.note);
    formData.append("wifi_router_id", data.wifi_router_id || null);
    formData.append("wifi_router_serial", data.wifi_router_serial || "");
    data.solutions.forEach((item, index) => {
      formData.append(`solutions[${index}]`, item);
    });
    formData.append("_method", "PUT");

    await taskApi.maintenanceDetailsForm(data.task_id, formData);

    dispatch(setLoading("maintenance_details"));
    dispatch(setMaintenanceDetailsDialog());
    dispatch(
      showNotification({
        type: "success",
        message: "تم حفط معلومات الصيانة",
      })
    );
    dispatch(getTaskByid(data.task_id));
  } catch (err) {
    dispatch(setLoading("maintenance_details"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const getCollectionFees = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("collection_fees_dialog"));
    const res = await taskApi.getCollectionFees(data);
    dispatch(setCollectionFeesAmount(res.data.data.collection_fees));
    dispatch(setLoading("collection_fees_dialog"));
  } catch (err) {
    dispatch(setLoading("collection_fees_dialog"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

const changeDelayed = (task_id, type) => async (dispatch) => {
  try {
    await taskApi.changeDelayed(task_id);

    dispatch(
      showNotification({
        type: "success",
        message: "تم تغيير حالة التأجيل",
      })
    );

    switch (type) {
      case "new-customers":
        dispatch(index());
        break;
      case "subscription-request-task":
        dispatch(subscriptionRequestTasks());
        break;
      case "maintenance-task":
        dispatch(maintenanceTasks());
        break;
      case "by-task-id":
        dispatch(getTaskByid(task_id));
        break;

      default:
        dispatch(index());
    }
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

const changeTaskTypeFromFtth = (data, type) => async (dispatch) => {
  try {
    dispatch(setLoading("change_task_type"));
    await taskApi.changeTaskTypeFromFtth({
      id: data.task_id,
      type_id: data.type_id?.id,
    });
    dispatch(setLoading("change_task_type"));
    dispatch(setChangeTaskTypeDialog());
    dispatch(
      showNotification({
        type: "success",
        message: "تم تغيير نوع المهمة",
      })
    );

    switch (type) {
      case "new-customers":
        dispatch(index());
        break;
      case "subscription-request":
        dispatch(subscriptionRequestTasks());
        break;
      case "maintenance":
        dispatch(maintenanceTasks());
        break;
      default:
        dispatch(index());
    }
  } catch (err) {
    dispatch(setLoading("change_task_type"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

export const Tasks = {
  index,
  status,
  solutions,
  sendSolutions,
  getTaskByid,
  changeStatus,
  create,
  deleteTask,
  deleteReply,
  assignResponsible,
  reply,
  types,
  maintenanceTasks,
  changeIsExternalStatus,
  getTasksNotifications,
  subscriptionRequestTasks,
  changeSubscriptionAccountRequestsTask,
  toExcel,
  toExcelTaskMaintenance,
  assignTeamLeader,
  addNewRecord,
  getRecordFullPath,
  maintenanceDetailsForm,
  checkMaintenanceDetailsPrice,
  getCollectionFees,
  changeDelayed,
  fatClearanceTasks,
  createFatClearance,
  changeTaskTypeFromFtth,
  //   removeReply,
  //   changeReplyPrivacy,
};
