import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/cabinet?${p}`);
  },
  create(data) {
    return api.post(`/api/v1/cabinet`, data);
  },
  update(data) {
    return api.put(`/api/v1/cabinet/${data.id}`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/cabinet/${id}`);
  },
  deletePole(id) {
    return api.delete(`/api/v1/pole/${id}`);
  },
  createPole(data) {
    return api.post(`/api/v1/pole`, data);
  },

  updatePole(data) {
    return api.put(`/api/v1/pole/${data.pole_id}`, data);
  },
  toggleStatus(id) {
    return api.put(`/api/v1/cabinet/active/${id}`);
  },
  setFullPole(pole_id) {
    return api.put(`/api/v1/pole/set-full/${pole_id}`);
  },
};
